import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgencyGroup } from '@core/models/agency-group.model';
import { GecoErrorMessage } from '@core/models/geco/geco-error-message.model';
import { AgencyGroupUtilsService } from '@core/services/agency-group-utils.service';
import { TaprofileUtilsService } from '@core/services/taprofile-utils.service';
import { TitleService } from '@core/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Constants } from '@app/app.constants';
import { SortingService } from '../../core/services/sorting.service';
import { ExportExcelService } from '@core/services/export-excel.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ta-agency-group',
  templateUrl: './agency-group.component.html',
})
export class AgencyGroupComponent implements OnInit, OnChanges {

  columns: { name: string; property: string; }[] = [
    { name: 'agencyGroup.agencyName', property: 'name' },
    { name: 'agencyGroup.agencyID', property: 'id' },
    { name: 'agencyGroup.status', property: 'status' },
  ];

  lastParams$: Observable<{ sortBy: string; ascending: boolean; }> = this.sortingService.agencyGroupParams$;
  sortedList: AgencyGroup[];

  public isEmpty: boolean;
  public isMissingLegalDetails = false;
  public hasPendingLegalDetails = false;
  public errorMessages: GecoErrorMessage[] = [];
  public profileTaxId: string;

  public agencyNoIdentified = Constants.AGENCY_GROUP_NO_IDENTIFIED;
  public agencyAssociatedThisTax = Constants.AGENCY_GROUP_ASSOCIATED_THIS_TAX;
  public agencyPending = Constants.AGENCY_GROUP_PENDING;
  public agencyAssociatedOtherTax = Constants.AGENCY_GROUP_NO_ASSOCIATED_OTHER_TAX;

  public agencies: AgencyGroup[];
  public currentAgency: AgencyGroup = new AgencyGroup();

  public activePage = 1;
  private pageSize = 5;
  public totalAgencies: number;
  public agencyListFrom: number;
  public agencyListTo: number;
  public showPagination: boolean;

  public isLastPage: boolean;
  public isFirstPage: boolean;

  public agencyListForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private taProfileUtilsService: TaprofileUtilsService,
    private agencyGroupUtilsService: AgencyGroupUtilsService,
    private translate: TranslateService,
    private titleService: TitleService,
    private sortingService: SortingService,
    private exportService: ExportExcelService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.initForm();
    this.titleService.setPageTitle('tabs.agencyGroup');
    this.retrieveAgencyGroupData();
  }

  ngOnChanges({ agencies }: SimpleChanges): void {
    if (agencies) {
      this.sortedList = this.agencies;
    }
  }

  private initForm() {
    this.agencyListForm = this.fb.group({
      agencyIdList: [this.setControlValue('agencyIdList'), Validators.required],
    });
  }

  get agencyIdList() { return this.agencyListForm.get('agencyIdList'); }

  hasControlValue(controlName: string) {
    return this.agencyListForm && this.agencyListForm[controlName];
  }

  private setControlValue(controlName: string) {
    return this.hasControlValue(controlName) ? this.agencyListForm[controlName] : '';
  }

  /**
   * Checks if the field has been modified and is invalid
   *
   * @param formControl the field to be validated
   * @returns true if the field invalid
   */
  isInvalid(formControl: AbstractControl): boolean {
    return formControl.invalid && formControl.touched;
  }

  /*
   * Send error message if the field has been modified and is invalid
   */
  isInvalidMessage(formControl: AbstractControl): string {
    if (formControl.invalid && formControl.touched) {
      if (formControl.errors.required) {
        return this.translate.instant('forms.required');
      }
    }
  }

  public agencyList(): void {
    if (this.agencies) {
      this.totalAgencies = this.agencies.length;
    }
    this.showPagination = this.totalAgencies > this.pageSize;
    this.activePage = 1;
    this.onChangePage(0);
  }

  public onChangePage(increment: number): void {
    this.activePage = this.activePage + increment;

    this.isFirstPage = this.activePage === 1;
    this.isLastPage = this.activePage >= (this.totalAgencies / this.pageSize);

    this.agencyListFrom = (this.activePage - 1) * this.pageSize;
    this.agencyListTo = this.activePage * this.pageSize;

    if (this.agencyListTo > this.totalAgencies) {
      this.agencyListTo = this.totalAgencies;
    }
  }

  public retrieveAgencyGroupData() {

    // Subscribe to AgencyInfo and call agencyGroup service
    this.taProfileUtilsService.getAgencyInfo().pipe(
      mergeMap(agvInfo => {

        this.profileTaxId = agvInfo.newInfo.legal.agencyTaxId;

        // Check hasPendingLegalDetails
        this.hasPendingLegalDetails = agvInfo.hasPendingChanges;

        // Check lelgal details
        if ((agvInfo.newInfo.legal.primaryTax === null && agvInfo.newInfo.legal.secondaryTax === null)
          || agvInfo.newInfo.legal.name === null) {
          this.isMissingLegalDetails = true;
        }
        return this.agencyGroupUtilsService.agencyGroupList;
      })
    ).subscribe(agencyGroupList => {
      this.agencies = agencyGroupList;
      this.isEmpty = this.agencies.length === 0;
      this.agencyList();
    });
  }

  public deleteAgencyFromGroup() {
    this.agencyGroupUtilsService.deleteAgency(this.currentAgency.id)
      .subscribe(agencyGroupList => {
        this.agencies = agencyGroupList.list;
        this.isEmpty = this.agencies.length === 0;
        this.agencyList();
      });
  }

  public addAgencyToGroup() {
    this.agencyGroupUtilsService.addAgencyList(this.agencyIdList.value)
      .subscribe(agencyGroupList => {
        this.agencies = agencyGroupList.list;
        this.isEmpty = this.agencies.length === 0;
        this.agencyList();
      });
  }

  public onAddAgency() {
    this.agencyIdList.patchValue(null);
    this.agencyListForm.markAsUntouched();
  }

  public onDeleteAgency(agency: AgencyGroup) {
    this.currentAgency = agency;
  }

  public onSortedList(sortedAgencyGroupResults: any[]) {
    this.sortedList = sortedAgencyGroupResults as AgencyGroup[];
    this.activePage = 1;
    this.onChangePage(0);
  }

  public saveLastParams(params: { sortBy: string; ascending: boolean; }) {
    this.sortingService.setAgencyGroupParams(params);
  }

  public onExportAsExcel() {
    const now: string = this.datePipe.transform(new Date(), 'yyyyMMddHHmmss');
    const list: any[] = [];

    const headers: string[] = [
      this.translate.instant('agencyGroup.agencyID'),
      this.translate.instant('agencyGroup.agencyName'),
      this.translate.instant('agencyGroup.status'),
      this.translate.instant('agencyGroup.statusComments')];
    const wscols = [
      { wch: 15 },
      { wch: 30 },
      { wch: 50 },
      { wch: 30 },
    ];

    let dspStatus;
    let comments;

    this.sortedList.forEach(item => {

      dspStatus = null;
      comments = null;

      switch (item.status) {
        case this.agencyNoIdentified: {
          dspStatus = this.translate.instant('agencyGroup.statusDisplayNoIdentified');
          break;
        }
        case this.agencyAssociatedThisTax: {
          dspStatus = this.translate.instant('agencyGroup.statusDisplayAssociatedThisTax');
          break;
        }
        case this.agencyPending: {
          dspStatus = this.translate.instant('agencyGroup.statusDisplayPending');
          break;
        }
        case this.agencyAssociatedOtherTax: {
          dspStatus = this.translate.instant('agencyGroup.statusDisplayAssociatedOtherTaxText1') + ' ' +
            this.translate.instant('agencyGroup.statusDisplayAssociatedOtherTaxText2') + ' ' +
            this.translate.instant('agencyGroup.statusDisplayAssociatedOtherTaxText3');
          comments = this.translate.instant('agencyGroup.statusMessageAssociatedOtherTax') + ' ' + item.taxCode;
          break;
        }
      }

      list.push({
        'ta_code': item.id,
        'ta_name': item.id === item.name ? '' : item.name,
        'ta_status': dspStatus,
        'ta_comments': comments,
      });
    });

    this.exportService.exportAsExcel({ data: list, fileName: 'agencyGroup_status_' + now, header: headers, wscols: wscols, table: null });
  }
}
