// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'dev',
  apiHost: 'profile-service.dev.onyxcentersource.com',
  apiUrl: 'https://profile-service.dev.onyxcentersource.com/rest/public/',
  gecoPaymentUrl: 'https://desa.payments.onyxcentersource.com/',
  commonServiceApiUrl: 'https://ocd-service.dev.onyxcentersource.com/api/geco/',
  captchaSiteKey: '6Lde7tkUAAAAACopMVlCUo-Zv2Tkvdp7XWd3F-JL',
  captchaBaseUrl: 'https://www.recaptcha.net/recaptcha/api.js',
  cdnUrl: 'https://cdn.dev.onyxcentersource.com/taprofile/',
  aprUrl: 'https://apr.dev.onyxcentersource.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
